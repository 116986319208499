import React from "react"
import "bulma/css/bulma.css"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../styles/sideNavigation.scss"

const SideNavigation = (props) => {
  const data = useStaticQuery(graphql`
        query{
          allContentfulStronaPodkategoria{
            edges{
              node{
                tytul,
                slug
              }
            }
          }
        }
    `)
  const { podkategorie, activeLink } = props
  const sortedPodkategorie = podkategorie && podkategorie.sort()
  const edges = data.allContentfulStronaPodkategoria.edges
  return (
    <React.Fragment>
      <div className="sidenav-container">
        {sortedPodkategorie && sortedPodkategorie.map((podkat, id) => {
            const slugObj = edges.filter(node => node.node.tytul.toLowerCase().replace(/[^a-z0-9]/gi, "") === podkat.toLowerCase().replace(/[^a-z0-9]/gi, ""))
            return (
              <Link key={`key-${id}`} to={`/${slugObj[0] && slugObj[0].node && slugObj[0].node.slug ? slugObj[0].node.slug : "/"}`}
                    className={`${activeLink && activeLink.toLowerCase() === podkat.toLowerCase() ? "active-link" : ""} sidenav-podkat-parent`}>{podkat}</Link>)
          },
        )}
      </div>
    </React.Fragment>
  )
}
export default SideNavigation
