import React from "react"
import "bulma/css/bulma.css"
import "../styles/stronaPodkategoria.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import { graphql, Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SideNavigation from "../components/sideNavigation"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import ReactPlayer from "react-player"
import Home from "@material-ui/icons/Home"
import { Helmet } from "react-helmet"

export const query = graphql`
  query($slug: String!) {
    contentfulStronaPodkategoria(slug: { eq: $slug }) {
      childContentfulStronaPodkategoriaTrescRichTextNode {
        json
      }
      tytul
      kategoria
    }
    allContentfulNawigacjaMenu {
      edges {
        node {
          kategoriaGlowna
          podkategorie
          slug
        }
      }
    }
  }
`
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const opis = node.data.target.fields.description && node.data.target.fields.description.pl
      const url = node.data.target.fields.file && node.data.target.fields.file.pl.url
      const width = node.data.target.fields.file && node.data.target.fields.file.pl.details.image.width
      const height = node.data.target.fields.file && node.data.target.fields.file.pl.details.image.height
      return node.data.target.fields ? (
        <React.Fragment>
          <br clear="left" />
          <img
            style={{ display: "block", margin: "0 auto", maxWidth: "450px", maxHeight: "450px" }}
            className="img-fluid"
            vspace="20"
            title={opis}
            height={height < 100 ? height * 1.3 : height}
            width={width < 300 ? width * 1.3 : width}
            alt={opis}
            src={url}
          />
        </React.Fragment>
      ) : (
        <div></div>
      )
    },
  },
  renderText: text => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderMark: {
    [MARKS.CODE]: embedded => (
      <ReactPlayer
        width="100%"
        style={{ maxWidth: "640px", display: "block", margin: "0 auto", padding: "10px 0" }}
        url={embedded[1]}
      />
    ),
  },
}
const StronaPodkategoria = props => {
  const tytul = props.data.contentfulStronaPodkategoria.tytul
  const kategoria = props.data.contentfulStronaPodkategoria.kategoria
  const body =
    props.data.contentfulStronaPodkategoria.childContentfulStronaPodkategoriaTrescRichTextNode &&
    props.data.contentfulStronaPodkategoria.childContentfulStronaPodkategoriaTrescRichTextNode.json &&
    documentToReactComponents(
      props.data.contentfulStronaPodkategoria.childContentfulStronaPodkategoriaTrescRichTextNode.json,
      options
    )
  const podkategorie =
    props.data.allContentfulNawigacjaMenu.edges &&
    props.data.allContentfulNawigacjaMenu.edges.filter(kat => kat.node.slug === kategoria)[0] &&
    props.data.allContentfulNawigacjaMenu.edges.filter(kat => kat.node.slug === kategoria)[0].node.podkategorie
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${tytul} Laser-Medica`}</title>
        <meta
          name="description"
          content="Dermatologia Laserowa i Estetyczna, Depilacja Laserowa. Zabiegi dermatochirurgiczne oraz ginekologia estetyczna. Szeroka oferta zabiegów dermatologicznych."
        />
      </Helmet>
      <_navigation />
      <section className="hero hero-small-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="kat-glowna-tytul">{tytul}</h1>
          </div>
        </div>
      </section>
      <div className="columns nav-bread-container">
        <nav style={{ marginBottom: "2rem" }} className="breadcrumb column is-offset-1 is-10" aria-label="breadcrumbs">
          <ul style={{ fontSize: "12px" }}>
            <li key="glowna">
              <Link className="ikona-home" to="/">
                <Home />
              </Link>
            </li>
            <li>
              <Link className="strona-glowna-bc" to={`/${kategoria}`}>
                {props.data.allContentfulNawigacjaMenu.edges.filter(e => e.node.slug === kategoria) &&
                  props.data.allContentfulNawigacjaMenu.edges.filter(e => e.node.slug === kategoria)[0].node
                    .kategoriaGlowna}
              </Link>
            </li>
            <li key={kategoria} className="is-active " aria-current="page">
              <Link to={`/${kategoria}`}>{tytul}</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="columns col-reverse-for-mobile">
        {podkategorie && (
          <div className="column is-offset-1 is-3-desktop is-3-tablet is-offset-0-tablet container-sidenav">
            <SideNavigation activeLink={tytul} podkategorie={podkategorie} />
          </div>
        )}
        <section
          className={`section column ${
            podkategorie ? `is-6` : `is-6 is-offset-3`
          } strona-podkategoria-glowna-container`}
        >
          <div className="is-medium container-opis-body">{body}</div>
        </section>
      </div>
      <Footer />
    </div>
  )
}
export default StronaPodkategoria
